import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StoreProvider } from "./store";
import { BrowserRouter as Router } from "react-router-dom";

/**
 * Attributes in #reactApp to modify behavior of the App
 *
 * @attribute {string} base_url - Router basename prop value
 * @attribute {string} language - Lang for the app, can be "en" or "fr"
 * @attribute {string} header - If the value is "TRUE" the AppHeader is shown
 * @attribute {string} id - Id of itinerary
 * @attribute {string} type - "atlas" to show the Atlas, by default the Itinerary is shown
 */
const reactApp = document.getElementById("reactApp");
const baseUrl =
  reactApp?.getAttribute("base_url") || process.env.REACT_APP_BASE_URL_DEFAULT;
const lang = reactApp?.getAttribute("language") || "en";
const header = reactApp?.getAttribute("header") || "";
const id = reactApp?.getAttribute("ttc-id");
const type = reactApp?.getAttribute("type") || "ATLAS";

// Create container for react portal (for breaking out of react wrapper)
const portalContainerEl = document.createElement("div");
portalContainerEl.setAttribute("id", "portal");
document.querySelector("body").appendChild(portalContainerEl);

const root = ReactDOM.createRoot(reactApp);
root.render(
  <React.StrictMode>
    <StoreProvider>
      <Router basename={baseUrl}>
        <App lang={lang} header={header} id={id} type={type} />
      </Router>
    </StoreProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
