import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { transformLineToPolygon } from "../utils";

//@TODO Move API URLs to .env variable and set a dev vs prod version
//https://serverless-stack.com/chapters/environments-in-create-react-app.html
//https://medium.com/serverlessguru/deploy-reactjs-app-with-s3-static-hosting-f640cb49d7e6
//https://medium.com/@a.carreras.c/development-and-production-variables-for-react-apps-c04af8b430a5
const urlParams = new URLSearchParams(window.location.search);
const API_DOMAIN =
  urlParams.get("domain") ||
  process.env.REACT_APP_API_DOMAIN ||
  "dev.tripper.app";
// const domain = API_DOMAIN?.includes("https://")
//   ? API_DOMAIN
//   : `https://${API_DOMAIN}`;

const domain = API_DOMAIN;

const version = process.env.REACT_APP_API_VERSION || "v2.1";
const apiKey = process.env.REACT_APP_API_KEY;
const ATLAS_URL = `${domain}/api/${version}/atlases`;
const ITINERARIES_URL = `${domain}/api/${version}/itineraries`;
const ROUTE_EXPLORER_URL = `${domain}/api/${version}/routes/all`;
const ROUTE_URL = `${domain}/api/${version}/routes`;

/**
 * Retrieves the Itinerary data from the end point
 * - using fetch()
 * @param id {string} Itinerary Id
 * @return {Promise<([]|*)[]>}
 */
export async function legacyFetchItinerary(id, options) {
  const req = await fetch(`${ITINERARIES_URL}/${id}/?_format=json`, options);
  const res = await req.json();
  if (res.message) {
    return null;
  }
  const route = res[0];
  let routePath;
  try {
    const routePathReq = await fetch(route.routeData.routePath);
    const routePathRes = await routePathReq.json();
    routePath = JSON.parse(routePathRes.routePath.replace(/&quot;/g, '"'));
  } catch (error) {
    console.warn(error);
  }
  const itineraryData = route.itineraryData[0];

  return {
    ...route,
    itineraryData: itineraryData,
    routeData: {
      ...route.routeData,
      routePath,
    },
  };
}

/**
 * Retrieves the Itinerary data from the end point
 * - using fetch()
 * @param id {string} Itinerary Id
 * @return {Promise<([]|*)[]>}
 */
export async function fetchItinerary(id, options) {
  const req = await fetch(`${ITINERARIES_URL}/${id}/?_format=json`, options);
  const res = await req.json();
  if (res.message) {
    return null;
  }
  const route = res[0];
  let routePath;
  try {
    const routePathReq = await fetch(route.routeData.routePath);
    const routePathRes = await routePathReq.json();
    routePath = JSON.parse(routePathRes.routePath.replace(/&quot;/g, '"'));
  } catch (error) {
    console.warn(error);
  }
  const itineraryData = route.itineraryData[0];
  //HACK: replace stops id with unique ones
  const stops = await itineraryData.stops.reduce(async (asyncResult, stop) => {
    const result = await asyncResult;
    if (stop.type === "place_end") {
      return [
        ...result,
        {
          ...stop,
          _id: stop.id,
          id: uuidv4(),
          _place_id: stop.place_id,
          place_id: result.find((item) => item._id === stop.place_id).id,
        },
      ];
    }
    if (stop.type === "place_overnight") {
      const relatedAtlas = stop.relatedAtlas || stop.en.relatedAtlas;
      const placeOutline = transformLineToPolygon(
        JSON.parse(stop.placeOutline)
      );
      const newId = uuidv4();
      try {
        if (relatedAtlas) {
          const res = await axios.get(relatedAtlas.teaser);
          const atlas = res.data.data[0][relatedAtlas.id];
          return [
            ...result,
            {
              ...stop,
              _id: stop.id,
              id: newId,
              placeOutline,
              placeData: atlas,
            },
          ];
        }
      } catch (error) {
        console.warn(error);
        return [
          ...result,
          {
            ...stop,
            _id: stop.id,
            id: newId,
            placeOutline,
            externalLink: stop.en.externalLink,
            relatedAtlas: null,
          },
        ];
      }
    }
    return [
      ...result,
      {
        ...stop,
        _id: stop.id,
        id: uuidv4(),
      },
    ];
  }, Promise.resolve([]));

  return {
    ...route,
    itineraryData: {
      ...itineraryData,
      stops,
    },
    routeData: {
      ...route.routeData,
      routePath,
    },
  };
}

/**
 * Retrieves the Atlas data from the end point
 * - using fetch()
 * @param id {string} Itinerary Id
 * @return {Promise<([]|*)[]>}
 */
export async function fetchAtlas(id, start = 0, limit = 10, tags, signal) {
  // const req = await fetch(
  //   `${ATLAS_URL}/${id}/?_format=json&limit=${limit}&start=${start}&tags=${tags}`,
  //   { signal }
  // );
  const req = await fetch(`${process.env.PUBLIC_URL}/dno-invest-data.json`, {
    signal,
  });
  const res = await req.json();
  console.log(res);
  const routeID = Object.keys(res)[0];
  const data = res[routeID];
  if (!data.placeData) {
    return null;
  }

  return {
    ...data,
    placeData: {
      ...data.placeData,
      placeOutline: JSON.parse(data.placeData.placeOutline),
    },
  };
}

/**
 * Retrieves the Route Explorer data from the end point
 * - using fetch()
 * @param start {number} Start index
 * @param limit {number} Total records retrieved
 * @param tagsType {string} Travel type
 * @param formatter {function} Function called per every item in response
 * @return {Promise<([]|*)[]>}
 */
export async function fetchRouteExplorer(
  start = 0,
  limit = 10,
  tagsType,
  formatter = (value) => value,
  signal
) {
  try {
    const req = await fetch(
      `${ROUTE_EXPLORER_URL}/?_format=json&limit=${limit}&start=${start}${
        ["ALL", undefined, ""].includes(tagsType) ? "" : `&tags=${tagsType}`
      }`,
      { signal }
    );
    const res = await req.json();
    const routes = res.routes || [];
    const routeIds = routes.map((route) => route.routeData.routeID);
    const routesById = routes.reduce((result, route, index) => {
      const routeId = route.routeData.routeID;
      return {
        ...result,
        [routeId]: {
          ...formatter(route, index),
          itineraryData: route.itineraryData[0],
          routeData: {
            ...route.routeData,
            routePathEndpoint: route.routeData.routePath,
          },
        },
      };
    }, {});

    if (res.message) {
      throw new Error(res.message);
    }

    return {
      totalResults: res.totalResults || 0,
      routeIds,
      routesById,
      filters: res.filters,
    };
  } catch (e) {
    return {
      totalResults: 0,
      routeIds: [],
      routesById: {},
      filters: [],
      error: e,
    };
  }
}

/**
 * Retrieves the Explorer (routes) data from the end point
 * - using fetch()
 * @param start {number} Start index
 * @param limit {number} Total records retrieved
 * @param tagsType {string} Travel type
 * @param formatter {function} Function called per every item in response
 * @return {Promise<([]|*)[]>}
 */
export async function fetchExplorer(
  start = 0,
  limit = 10,
  tagsType,
  formatter = (value) => value,
  signal
) {
  try {
    const version = "v3";
    const EXPLORER_URL = `${domain}/api/${version}/routes/teasers`;
    const req = await fetch(`${EXPLORER_URL}/?_format=json&team=2`, { signal });
    const res = await req.json();
    const routes = res.routes || [];
    const atlases = res.atlases || [];
    const atlasIds = atlases.map((route) => route.atlasData.atlasID);
    const routeIds = routes.map((route) => route.routeData.routeID);
    const atlasesById = atlases.reduce((result, atlas, index) => {
      const atlasId = atlas.atlasData.atlasID;
      return {
        ...result,
        [atlasId]: {
          ...formatter(atlas, index),
          atlasData: {
            ...atlas.atlasData,
          },
        },
      };
    }, {});
    const routesById = routes.reduce((result, route, index) => {
      const routeId = route.routeData.routeID;
      return {
        ...result,
        [routeId]: {
          ...formatter(route, index),
          routeData: {
            ...route.routeData,
            routePathEndpoint: route.routeData.routePath,
          },
        },
      };
    }, {});

    if (res.message) {
      throw new Error(res.message);
    }

    return {
      totalResults: res.totalResults || 0,
      atlasIds,
      atlasesById,
      routeIds,
      routesById,
      filters: res.filters,
    };
  } catch (e) {
    return {
      totalResults: 0,
      atlasIds: [],
      atlasesById: {},
      routeIds: [],
      routesById: {},
      filters: [],
      error: e,
    };
  }
}

export async function fetchRoute(id, signal) {
  try {
    const req = await fetch(`${ROUTE_URL}/${id}/?_format=json`, { signal });
    const res = await req.json();
    if (res.message) {
      throw new Error(res.message);
    }
    return {
      data: res[0],
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: e,
    };
  }
}

export async function fetchStopTags() {
  const req = await fetch(`${domain}/api/${version}/tags/all?_format=json`);
  const res = await req.json();
  if (res.message) {
    return null;
  }
  return res;
}

export async function fetchStops({
  distance,
  coords,
  tagIds,
  start = 0,
  limit = 20,
}) {
  const distanceStr = distance ? `&distance=${distance}` : "";
  const coordsStr = coords ? `&searchPoint=${coords.lat},${coords.lon}` : "";
  const tagIdsStr = tagIds ? `&tags=${tagIds.join(",")}` : "";
  const req = await fetch(
    `${domain}/api/${version}/stops/all?_format=json&start=${start}&limit=${limit}${distanceStr}${coordsStr}${tagIdsStr}`
  );
  const res = await req.json();
  if (res.message) {
    return null;
  }
  return res;
}

export async function fetchStopsByBBox({
  boundsSW,
  boundsNE,
  tagIds,
  start = 0,
  limit = 20,
}) {
  const boundsSWStr = boundsSW
    ? `&boundsSW=${boundsSW.lat},${boundsSW.lng}`
    : "";
  const boundsNEStr = boundsNE
    ? `&boundsNE=${boundsNE.lat},${boundsNE.lng}`
    : "";
  const tagIdsStr = tagIds ? `&tags=${tagIds.join(",")}` : "";
  if (!boundsSWStr || !boundsNEStr) {
    return null;
  }
  const req = await fetch(
    `${domain}/api/${version}/stops/all?_format=json&start=${start}&limit=${limit}${boundsSWStr}${boundsNEStr}${tagIdsStr}`
  );
  const res = await req.json();
  if (res.message) {
    return null;
  }
  return res;
}

export async function fetchStop(stopId) {
  const req = await fetch(
    `${domain}/api/${version}/stops/${stopId}?_format=json`
  );
  const res = await req.json();
  if (res.message) {
    return null;
  }
  return res;
}

export async function fetchStopsByName(name) {
  const req = await fetch(
    `${domain}/api/${version}/stops/search?_format=json&name=${name}`
  );
  const res = await req.json();
  if (res.message) {
    return null;
  }
  return res;
}

export async function fetchItinerariesByName(name) {
  // const req = await fetch(`${domain}/api/${version}/itineraries/search?_format=json&name=${name}`);
  // const res = await req.json();
  // if (res.message) {
  //     return null;
  // }
  // return res;
  const req = await fetch(
    `${domain}/api/${version}/routes/11033/?_format=json`
  );
  const res = await req.json();
  if (res.message) {
    return null;
  }
  return {
    itineraries: res[0].itineraryData,
  };
}

export async function sendFavoritesToEmail({ email, stopIds }) {
  try {
    const version = "v3";
    const req = await fetch(
      `${domain}/api/${version}/email/stops?_format=json&email=${email}&stopIDs=${stopIds.join(
        ","
      )}`
    );
    const res = await req.json();
    return res;
  } catch (e) {
    return null;
  }
}

// https://github.com/TNB3000/tripper/issues/232
export async function updateItinerary({ body }) {
  try {
    const version = "v3";
    const req = await fetch(
      `${domain}/api/${version}/itinerary/update?_format=json`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    const res = await req.json();
    return res;
  } catch (e) {
    return null;
  }
}

export async function fetchLocations(query) {
  const token =
    "pk.eyJ1IjoidG5iM2siLCJhIjoiY2ptNm14bHQ2MWNubzN3bjJmZjJxOWlwOCJ9.JM2XrfZHVM26uw2bQAjZsA";
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?country=CA&access_token=${token}`;
  const places = await (await fetch(url)).json();

  return places.features.map((place) => {
    const placeName = place.place_name.split(",");
    return {
      id: place.id,
      name: placeName[0],
      desc: placeName.splice(1, placeName.length).join(","),
      coordinates: place.geometry.coordinates,
    };
  });
}

export async function fetchItineraryTeaser(id) {
  try {
    const req = await axios.get(
      `${domain}/api/v3/itinerary_teaser/${id}?_format=json`
    );
    return req.data;
  } catch (error) {
    console.warn(error);
    return null;
  }
}

export async function fetchVanillaItinerary(id) {
  try {
    const req = await axios.get(`${ITINERARIES_URL}/${id}?_format=json`);
    return req.data;
  } catch (error) {
    console.warn(error);
    return null;
  }
}
