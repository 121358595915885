import "./App.scss";
import "./trpr-foundations/css/main.css";
import "url-search-params-polyfill";
import "mapbox-gl/dist/mapbox-gl.css";

import React, { useEffect, useState } from "react";
import { useEffectOnce } from "react-use";
import { useMatch } from "react-router-dom";
import translate, { TranslatorContext } from "./translate";
import { BreakpointsProvider } from "./trpr-foundations/hooks";
import loadable from "@loadable/component";

const LoadableAtlas = loadable(() =>
  import(/* webpackChunkName: "Atlas" */ "./components/atlas/Atlas")
);

/**
 * URL parameters to modify behavior of the App
 *
 * @param {string} lang - Lang for the app, can be "en" or "fr"
 * @param {string} id - Id of itinerary
 * @param {string} type - "atlas" to show the Atlas, by default the Itinerary is shown
 * @param {string} showHeader - If the value is "true" the AppHeader is shown
 */
const urlParams = new URLSearchParams(window.location.search);
const cachedLang = urlParams.get("lang");
const cachedId = urlParams.get("id");
const cachedType = urlParams.get("type");
const cachedShowHeader = urlParams.get("showHeader");
const cachedTargetItinerary = urlParams.get("targetItinerary");
const cachedInLauncher = urlParams.get("inLauncher");
const cachedInLauncherModal = urlParams.get("inModal");
const cachedKey = urlParams.get("key");

export const useTranslator = (defaultLang) => {
  const [translator, setTranslator] = useState(() => () => "");
  const lang = defaultLang.toLowerCase();
  useEffect(() => {
    setTranslator(() => translate(lang));
  }, [lang]);
  return translator;
};

function App(props) {
  const [inExplorer, setInExplorer] = useState();
  const translator = useTranslator(props.lang);
  const id = cachedId || props.id;
  const type = (cachedType || props.type).toLowerCase();
  const showHeader =
    (cachedShowHeader || props.header).toLowerCase() === "true";
  const inLauncher = cachedInLauncher?.toLowerCase() === "true";
  const inLauncherModal = cachedInLauncherModal?.toLowerCase() === "true";
  const targetItinerary = cachedTargetItinerary;
  const key = cachedKey;
  const rootRoute = useMatch("/");

  useEffectOnce(() => {
    let inExplorer = false;
    if (rootRoute?.isExact && !type && !id) {
      inExplorer = true;
    }
    setInExplorer(inExplorer);
  });

  if (inExplorer === undefined) {
    return null;
  }

  return (
    <BreakpointsProvider>
      <TranslatorContext.Provider value={translator}>
        <main className="trpr__base_container">
          <LoadableAtlas atlasId={id} />
        </main>
      </TranslatorContext.Provider>
    </BreakpointsProvider>
  );
}

App.defaultProps = {
  type: "itinerary",
};

export default App;
