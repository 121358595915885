import intersection from "lodash.intersection";
import lineToPolygon from "@turf/line-to-polygon";
import { useMeasure } from "react-use";

// source: https://github.com/twbs/bootstrap/blob/e8f08d1802976b8200551de49354757f84e438cf/js/src/modal.js#L494
function getScrollbarWidth() {
    const scrollDiv = document.createElement("div");
    scrollDiv.style.position = "absolute";
    scrollDiv.style.top = "-9999px";
    scrollDiv.style.width = "50px";
    scrollDiv.style.height = "50px";
    scrollDiv.style.overflow = "scroll";
    document.body.appendChild(scrollDiv);
    const scrollbarWidth = scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
    return scrollbarWidth;
}

export function lockScrollbar() {
    const scrollbarWidth = getScrollbarWidth();
    const actualOverflow = document.body.style.overflow;
    const actualPaddingRight = document.body.style.paddingRight;
    const computedPaddingRight = window.getComputedStyle(document.body).paddingRight;
    document.body.setAttribute("data-bs-overflow", actualOverflow);
    document.body.setAttribute("data-bs-padding-right", actualPaddingRight);
    document.body.style.overflow = "hidden";
    document.body.style.paddingRight = `${scrollbarWidth + (Number.parseFloat(computedPaddingRight) || 0)}px`;
    document.querySelector("html").style.overflow = "initial";
}

export function unlockScrollbar() {
    const prevOverflow = document.body.getAttribute("data-bs-overflow");
    const prevPaddingRight = document.body.getAttribute("data-bs-padding-right");
    document.body.removeAttribute("data-bs-overflow");
    document.body.removeAttribute("data-bs-padding-right");
    document.body.style.overflow = prevOverflow;
    document.body.style.paddingRight = prevPaddingRight;
    document.querySelector("html").style.overflow = null;
}

export function getFilteredStops(stops, filters) {
    if (!stops) {
        return [];
    }
    if (!filters.length) {
        return stops;
    }
    const filterIds = filters.map(filter => filter.id);
    return stops.filter(stop => {
        const stopTags = stop?.tags?.tags ?? [];
        const stopTagIds = stopTags.map(tag => tag.id);
        return intersection(stopTagIds, filterIds).length === filterIds.length;
    });
}

export function transformLineToPolygon(geojsonObject) {
    // HACK: transform line to polygon
    // needed to display the map correctly
    if (geojsonObject?.features?.length) {
        geojsonObject.features = geojsonObject.features.map(feature => {
            if (feature.geometry.type === "LineString") {
                return lineToPolygon(geojsonObject.features[0]);
            }
            return feature;
        });
    }
    return geojsonObject;
}


export const useElementQueries = sizes => {
    const [ref, { width }] = useMeasure();
    const minWidth = sizes.filter(size => width >= size).join(" ");
    return [ref, { attrs: { "data-min-width": minWidth } }];
};

export const getDaysCount = itineraryData => {
    try {
        const overnightStops = itineraryData?.stops?.filter(
            stop => stop?.stopType?.label?.toUpperCase() === "OVERNIGHT",
        );
        const sum = overnightStops.reduce((sum, item) => {
            return sum + parseInt(item?.multiNight?.numberOfNights ?? 1);
        }, 0);
        //Add 1 because last stop isn't overnight
        return sum + 1;
    } catch (error) {
        console.warn(error);
        return 0;
    }
};

export const getDistance = itineraryData => {
    try {
        const distance = itineraryData.stops
            .filter(item => item.type === "stop")
            .reduce((result, item, index) => {
                if (index === 0) {
                    return result;
                }
                const itemDistance = item.type === "stop" && item?.distanceFromPrevStop;
                if (itemDistance) {
                    return result + itemDistance / 1000;
                }
                return result;
            }, 0);
        return parseInt(distance);
    } catch (error) {
        console.warn(error);
        return 0;
    }
};

// Add attributes target="_blank" and rel="noopener noreferrer" to every <a /> tag
export const decorateRichText = html => {
    if (!html || typeof html !== "string") {
        return html;
    }
    const domParser = new DOMParser();
    const document = domParser.parseFromString(html, `text/html`);

    // Handles external links
    const links = document.querySelectorAll(`a`);

    links.forEach(link => {
        if (link.href) {
            link.target = `_blank`;
            link.rel = `noopener noreferrer`;
        }
    });
    const newHtml = document.body.innerHTML;
    return newHtml;
};
