/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import {
  useCallback,
  Fragment,
  useMemo,
  useState,
  useEffect,
  createContext,
  useContext,
} from "react";
import { useLockBodyScroll } from "react-use";
import debounce from "lodash.debounce";
import throttle from "lodash.throttle";
import { LayoutGroup, AnimatePresence, motion } from "framer-motion";
import {
  isMacLike,
  mobileMediaQuery,
  tabletMediaQuery,
  desktopMediaQuery,
  extraSmallMediaQuery,
  slideUpVariant,
  fadeSlideUpVariant,
  fadeVariants,
  motionTransition,
} from "../constants";
import random from "lodash.random";
import { useAsync } from "react-use";
import {
  fetchExplorer,
  fetchRoute,
  fetchItineraryTeaser,
  fetchVanillaItinerary,
} from "./api";
import { getDaysCount, getDistance } from "./utils";

export const useWindowHeight = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  useResize(() => {
    setWindowHeight(window.innerHeight);
  });
  return windowHeight;
};

const getIsMobile = () => window.matchMedia(mobileMediaQuery).matches;
const getIsTablet = () => window.matchMedia(tabletMediaQuery).matches;
export const getIsDesktop = () => window.matchMedia(desktopMediaQuery).matches;
export const getIsExtraSmall = () =>
  window.matchMedia(extraSmallMediaQuery).matches;

function useBreakpoints() {
  const [isMobile, setIsMobile] = useState(getIsMobile());
  const [isTablet, setIsTablet] = useState(getIsTablet());
  const [isDesktop, setIsDesktop] = useState(getIsDesktop());
  const [isExtraSmall, setIsExtraSmall] = useState(getIsExtraSmall());

  useEffect(() => {
    function handleResize() {
      setIsMobile(getIsMobile());
      setIsTablet(getIsTablet());
      setIsDesktop(getIsDesktop());
      setIsExtraSmall(getIsExtraSmall());
    }
    const debouncedHandleResize = debounce(handleResize, 300);
    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      debouncedHandleResize.flush();
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  return {
    isMobile,
    isTablet,
    isDesktop,
    isExtraSmall,
  };
}

// callback arg must use useCallback hook
export function useScroll(callback, timeout = 300) {
  useEffect(() => {
    const throttledHandleScroll = throttle(callback, timeout);
    window.addEventListener("scroll", throttledHandleScroll);
    return () => {
      throttledHandleScroll.flush();
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  }, [callback, timeout]);
}

// callback arg must use useCallback hook
export function useResize(callback, timeout = 300) {
  useEffect(() => {
    const throttledHandle = throttle(callback, timeout);
    window.addEventListener("resize", throttledHandle);
    throttledHandle();
    return () => {
      throttledHandle.flush();
      window.removeEventListener("resize", throttledHandle);
    };
  }, [callback, timeout]);
}

const BreakpointsContext = createContext();

export function BreakpointsProvider(props) {
  const breakpoints = useBreakpoints();
  return (
    <BreakpointsContext.Provider value={breakpoints}>
      {props.children}
    </BreakpointsContext.Provider>
  );
}

export const useBreakpointsContext = () => useContext(BreakpointsContext);

const modalBackdropCSS = css`
  background: rgba(51, 51, 51, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 6;
`;
const modalCSS = css`
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 6;
  overflow: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  pointer-events: none;

  &::before,
  &::after {
    content: "";
    flex: 1 1 0;
    width: 0;
  }

  > * {
    flex: none;
    pointer-events: all;
  }
`;
const ModalWithBackdrop = (props) => {
  const { children, showModal, closeModal, motionProps } = props;

  return useMemo(() => {
    const motionPropsCSS = motionProps?.css;
    return (
      <Fragment>
        <AnimatePresence>
          {showModal && (
            <motion.div
              animate="visible"
              css={modalBackdropCSS}
              exit="hidden"
              initial="hidden"
              transition={motionTransition}
              variants={fadeVariants}
              onClick={closeModal}
            ></motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {showModal && (
            <motion.div
              animate="visible"
              exit="hidden"
              initial="hidden"
              transition={motionTransition}
              variants={fadeVariants}
              {...motionProps}
              css={[modalCSS, motionPropsCSS]}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </Fragment>
    );
  }, [children, showModal, closeModal, motionProps]);
};
export const useModal = (options = {}) => {
  const { motionProps } = options;
  const [showModal, setShowModal] = useState(false);
  const lockBodyScroll = !isMacLike && showModal;
  const closeModal = useCallback(() => setShowModal(false), []);
  const modalProps = useMemo(() => {
    return { motionProps, showModal, closeModal };
  }, [motionProps, showModal, closeModal]);

  useLockBodyScroll(lockBodyScroll);

  return [showModal, setShowModal, ModalWithBackdrop, modalProps];
};

export const useReservationModalOptions = ({
  isGreaterThan530,
  childrenRef,
}) => {
  const reservationModalOptions = useMemo(
    () => ({
      motionProps: {
        key: isGreaterThan530 ? "desktop" : "mobile",
        variants: isGreaterThan530 ? fadeVariants : slideUpVariant,
      },
      childrenRef,
    }),
    [isGreaterThan530, childrenRef]
  );
  return reservationModalOptions;
};

const toastsCSS = css`
  position: fixed;
  top: 0;
  right: 0;
`;
const Toasts = (props) => {
  const { renderToast, toasts, className, motionProps } = props;
  return useMemo(() => {
    return (
      <LayoutGroup>
        <motion.div
          layout
          transition={motionTransition}
          css={!className ? toastsCSS : undefined}
          className={className}
        >
          <AnimatePresence>
            {toasts.map((toast) => (
              <motion.div
                layout
                key={toast.id}
                animate="visible"
                exit="hidden"
                initial="hidden"
                transition={motionTransition}
                variants={fadeSlideUpVariant}
                {...motionProps}
              >
                {renderToast(toast.props)}
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
      </LayoutGroup>
    );
  }, [toasts, className, renderToast, motionProps]);
};
export const useToast = (options) => {
  const { defaultTimeout, renderToast, motionProps } = options;
  const [toasts, setToasts] = useState([]);

  const showToast = useCallback(
    async ({ timeout = defaultTimeout, toastProps } = {}) => {
      let timeoutId;
      const toastId = random(0, 9999);
      const hideToast = () => {
        setToasts((toasts) => toasts.filter((toast) => toast.id !== toastId));
        clearTimeout(timeoutId);
      };
      //Show toast
      setToasts((toasts) => [
        ...toasts,
        {
          props: {
            ...toastProps,
            hideToast,
          },
          id: toastId,
        },
      ]);
      //Hide toast after timeout
      await new Promise((resolve) => {
        timeoutId = setTimeout(() => {
          if (timeout !== Infinity) {
            hideToast();
          }
          resolve();
        }, timeout);
      });
    },
    [defaultTimeout]
  );
  const toastsProps = useMemo(
    () => ({
      renderToast,
      toasts,
      motionProps,
    }),
    [renderToast, toasts, motionProps]
  );

  return [showToast, Toasts, toastsProps];
};

const defaultRoutes = {
  routesIds: [],
  routesById: {},
  loading: false,
  error: null,
  totalCount: 0,
};
export const usePagedRoutes = (start, limit, tags) => {
  const [routes, setRoutes] = useState(defaultRoutes);
  const [, setAbortController] = useState();
  useEffect(() => {
    const callback = async () => {
      setRoutes((prevRoutes) => ({
        ...prevRoutes,
        loading: true,
        error: null,
      }));
      window.scrollTo({
        left: 0,
        top: 0,
      });
      const _abortController = new AbortController();
      setAbortController((abortController) => {
        if (abortController) {
          abortController.abort();
        }
        return _abortController;
      });
      const {
        routeIds: routesIds,
        totalResults: totalCount,
        ..._routes
      } = await fetchExplorer(
        start,
        limit,
        tags,
        undefined,
        _abortController.signal
      );
      setRoutes({
        ..._routes,
        routesIds,
        totalCount,
        loading: false,
      });
    };
    callback();
  }, [start, limit, tags]);
  return routes;
};

const defaultRouteData = { loading: false, error: null, route: null };
export const useSingleRoute = (id) => {
  const [data, setData] = useState(defaultRouteData);
  const [, setAbortController] = useState();
  useEffect(() => {
    const callback = async () => {
      setData((prevData) => ({
        ...prevData,
        loading: true,
        error: null,
      }));
      window.scrollTo({
        left: 0,
        top: 0,
      });
      const newAbortController = new AbortController();
      setAbortController((abortController) => {
        if (abortController) {
          abortController.abort();
        }
        return newAbortController;
      });
      const { data: route, error } = await fetchRoute(
        id,
        newAbortController.signal
      );
      setData({
        route,
        error,
        loading: false,
      });
    };
    callback();
  }, [id]);
  return data;
};

/**
 * Fetch itineraries and calculate their respective days and distance
 * @param {string[]} itineraryIds - List of itinerary ids
 * @param {boolean} isDisabled - Wait until isDisabledis true to initialize fetch/requests
 * @returns {[object[], boolean]} Itineraries + calculated days and distance and isLoading
 */
export const useItineraryTeasers = (itineraryIds, isDisabled = false) => {
  const [itineraries, setItineraries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useAsync(async () => {
    try {
      if (isDisabled) {
        return;
      }
      if (!itineraryIds) {
        return;
      }
      setIsLoading(true);
      const itineraryTeasers = await Promise.all(
        itineraryIds.map((itineraryId) => fetchItineraryTeaser(itineraryId))
      );
      if (itineraryTeasers.length) {
        const newItineraries = itineraryTeasers
          .map((item, index) => {
            if (!item) {
              return null;
            }
            return {
              ...item,
              id: itineraryIds[index],
              distance: 0,
              days: 0,
            };
          })
          .filter(Boolean);
        setItineraries(newItineraries);
      }
      setIsLoading(false);
      const itineraries = await Promise.all(
        itineraryIds.map((itineraryId) => fetchVanillaItinerary(itineraryId))
      );
      if (itineraries.length) {
        const newItineraries = itineraryTeasers
          .map((itineraryTeaser, index) => {
            if (!itineraryTeaser) {
              return null;
            }
            const itineraryData = itineraries[index][0]?.itineraryData?.[0];
            const days = getDaysCount(itineraryData);
            const id = itineraryIds[index];
            const distance = getDistance(itineraryData);

            return {
              ...itineraryTeaser,
              id,
              distance,
              days,
            };
          })
          .filter(Boolean);
        setItineraries(newItineraries);
      }
    } catch (error) {
      console.warn(error);
    }
  }, [itineraryIds, isDisabled]);

  return [itineraries, isLoading];
};

export function useForm(initial = {}) {
  // create a state object for our inputs
  const [inputs, setInputs] = useState(initial);
  const initialValues = Object.values(initial).join("");

  useEffect(() => {
    setInputs(initial);
  }, [initialValues]);

  function handleChange(e) {
    if (typeof e === "string") {
      setInputs({
        ...inputs,
        shippingAddress: e,
      });
    } else if (e.type === "FeatureCollection") {
      setInputs({
        ...inputs,
        shippingAddress: e.features[0].properties.full_address,
        line1: e.features[0].properties.address_line1,
        city: e.features[0].properties.place,
        state: e.features[0].properties.region,
        postal_code: e.features[0].properties.postcode,
        country: e.features[0].properties.country,
      });
    } else {
      let { value, name, type } = e.target;
      //browsers always return numbers as strings
      if (type === "number") {
        value = parseInt(value);
      }
      if (type === "file") {
        [value] = e.target.files;
      }
      setInputs({
        // copy the existing state
        ...inputs,
        [name]: value,
      });
    }
  }

  function resetForm() {
    setInputs(initial);
  }

  function clearForm() {
    const blankState = Object.fromEntries(
      Object.entries(inputs).map(([key, value]) => [key, ""])
    );
    setInputs(blankState);
  }

  return {
    inputs,
    handleChange,
    resetForm,
    clearForm,
  };
}
